import { ref, onMounted } from '@nuxtjs/composition-api';
import useApi from '~/composables/useApi';
import bestSellersQuery from './bestSellersQuery';

export const useBestSellers = (id) => {
  const { query } = useApi();
  const bestSellers = ref(null);
  const categoryName = ref(null);

  interface ResponseData {
    data?: {
      category?: {
        products?: any[]; 
        name?: string;  
      };
    };
  }

  onMounted(() => {
    query(bestSellersQuery, { id: id })
      .then((data: ResponseData) => {
        bestSellers.value = data?.data?.category?.products || [];
        categoryName.value = data?.data?.category?.name || '';
        return data;
      })
      .catch(() => {
        bestSellers.value = false;
      });
  });

  return {
    bestSellers,
    categoryName
  };
};

export default useBestSellers;