import { gql } from 'graphql-request';

export default gql`
query ($id: Int!) {
  category(id: $id) {
    name
    products {
      items {
        name
        recommended_retail_price
        image {
          disabled
          label
          position
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
          }
          maximum_price {
            regular_price {
              value
            }
          }
        }
        url_key
        url_suffix
        url_rewrites{
          url
        }
      }
    }
  }
}
`;

