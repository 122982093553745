//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from "@nuxtjs/composition-api";
import useBestSellers from "./bestSellers/useBestSellers";
import VueSlickCarousel from "vue-slick-carousel";
import BestSellersCard from "../BestSellersCard.vue";
import { Icon } from "@iconify/vue2";

export default defineComponent({
  name: "BestSellers",
  props: ["blok"],
  components: {
    VueSlickCarousel,
    BestSellersCard,
    Icon,
  },
  setup(props) {
    const { bestSellers, categoryName } = useBestSellers(
      props.blok?.category_id
    );
    const settings = ref({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: 4,
      speed: 500,
      rows: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    });

    return {
      bestSellers,
      categoryName,
      settings,
    };
  },
});
